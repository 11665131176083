import React from "react";
import SEO from "../components/seo/seo";
import { injectIntl } from "gatsby-plugin-react-intl";
import { Link  } from "gatsby-plugin-react-intl";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";

export const query = graphql`query getPost($slug: String!) {
  mdx(frontmatter: {slug: {eq: $slug}}) {
    frontmatter {
      title
      slug
      date(formatString: "Do MMMM, YYYY")
      author
      image {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
    body
  }
}
`;

const allPosts = ({ data, intl }) => {
	const { title, date, author, image } = data.mdx.frontmatter;
	const { body } = data.mdx;
	const img = image.childImageSharp.gatsbyImageData;
	return (
        <div className="postsPages">
			<SEO title={intl.formatMessage({ id: "navbar.news" })} />
			<div className="postsPagesTitleDiv">
				<div className="postsPagesTitle"><p>{title}</p></div>
				<div className="postsPagesDate"><span className="text-secondary"> {author} </span> / <span>{date}</span></div>
			</div>
			<div className="boxPostsPagesImage"><GatsbyImage image={img} className="postsPagesImage" loading="eager" alt="allPosts image" /></div>
            <div className="postsPagesText"><MDXRenderer>{body}</MDXRenderer></div>
			<div className="postsPagesButtonDiv"><Link to="/news#newsCardsSectionId"><button type="button" className="postsPagesButton"><FormattedMessage id="news.back" /></button></Link></div>
		</div>
    );
};

export default injectIntl(allPosts);
